<template lang="pug">
div(class="app")
  div(class="header")
    img(
      class="clc-logo"
      src="../assets/logo.png"
    )
  div(class="container pb-5")
    div(v-if="finishData.success && !resignPage" class="my-4")
      Finish(:finishData="finishData" @resign="resignPage=true")
    div(v-if="resignPage && !multipleSignInPage && !errorMessage" class="my-4")
      ReSignIn(@submit="handleReSignIn")
    div(v-if="multipleSignInPage && !errorMessage" class="my-4")
      NameCheck(:loveSevenRegistration="loveSevenRegistration" @submit="handleSignInMultiple")
  div(
    v-if="errorMessage"
    class="container")
    Error(
      :errorMessage="errorMessage"
      :isShowBackButton="isShowBackButton"
      @resign="handleErrorResign"
    )
</template>
<script>
import Finish from '../components/SignIn/finish'
import ReSignIn from '../components/SignIn/reSignIn.vue'
import NameCheck from '../components/SignIn/nameCheck.vue'
import Error from '../components/SignIn/error.vue'
export default {
  name: 'SignIn',
  components: {
    Finish,
    ReSignIn,
    NameCheck,
    Error
  },
  data () {
    return {
      autoSignInData: {
        isAuto: true,
        token: '',
        phone: ''
      },
      finishData: {},
      resignPage: false,
      errorMessage: null,
      multipleSignInPage: false,
      isShowBackButton: false
    }
  },
  methods: {
    async autoSignInFormData (data) {
      try {
        let res = await this.$api.main.autoSignInFormData(data)
        if (res.content && res.content.success) {
          this.finishData = res
          this.resignPage = false
        }
        if (res.content && !res.content.success && res.content.loveSevenRegistration.length === 0) {
          this.resignPage = true
        }
        if (res.content && !res.content.success && res.content.loveSevenRegistration.length > 1) {
          this.multipleSignInPage = true
          this.loveSevenRegistration = res.content.loveSevenRegistration
        }
        if (!res.success) {
          this.errorMessage = res.message
          this.isShowBackButton = true
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleErrorResign () {
      this.errorMessage = null
      this.resignPage = true
    },
    handleReSignIn (phone) {
      let data = {
        isAuto: false,
        token: '',
        phone: phone
      }
      this.autoSignInFormData(data)
    },
    async handleSignInMultiple (value) {
      try {
        let res = await this.$api.main.registrationSignInFormData({
            registrationId: value
        })
        if (!res.showalert && !res.success) {
          this.errorMessage = res.message
        } else {
          this.finishData = res
          this.resignPage = false
          this.multipleSignInPage = false
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  async created () {
    if (this.$router.history.current.query.step === 'reSignIn') {
      this.resignPage = true
    } else {
      const clc_form_info_cookie = document.cookie.split(';').find((item) => { return item.indexOf('clc_form_info') !== -1 });
      if (clc_form_info_cookie) {
        this.autoSignInData = {
          isAuto: true,
          token: clc_form_info_cookie.split('clc_form_info=')[1],
          phone: ''
        }
      }
      await this.autoSignInFormData(this.autoSignInData)
    }  
  }
}
</script>
