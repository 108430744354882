import BaseAPI from './base'

const mainAPI = {
  // 報名
  getClcGroupSelect (data) { return BaseAPI('get', `/Registration/SelectClcGroup`, data) },
  getFormData (data) { return BaseAPI('post', `/Registration/GetFormData`, data) },
  getFormDataDesc (data) { return BaseAPI('post', `/Registration/GetFormDataDesc`, data) },
  sendFormData (data) { return BaseAPI('post', `/Registration/SendFormData`, data) },

  // 簽到
  autoSignInFormData (data) { return BaseAPI('post', `/Registration/AutoSignInFormData`, data) },
  registrationSignInFormData (data) { return BaseAPI('post', `/Registration/RegistrationSignInFormData`, data) },
  sendSignInFormData (data) { return BaseAPI('post', `/Registration/SendSignInFormData`, data) }
}
export default mainAPI
