<template lang="pug">
div(class="desc")
  div(class="mt-3 py-3 message")
    p(class="mb-0 fs-5 text-center success") {{ errorMessage }}
  div(class="mt-5 justify-content-center")
    button(
        v-if="isShowBackButton"
        class="btn btn-main rounded-pill mx-1"
        @click.prevent="handleResign"
      ) 我要重填簽到
</template>
<script>

export default {
  name: 'Error',
  props: {
    errorMessage: {
      type: String
    },
    isShowBackButton: {
      type: Boolean
    }
  },
  methods: {
    handleResign () {
      this.$emit('resign')
    }
  }
}
</script>
