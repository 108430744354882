<template lang="pug">
div
  h2 戀愛七學分課程現場報名
  ValidationObserver(v-slot="{ handleSubmit }")
    form(@submit.prevent="handleSubmit(sendFormData)")
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span(class="text-danger") *
          span 組別
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="組別" rules="required" v-slot="{ errors }")
            select(
              v-model="groupType"
              class="form-select")
              option(value="" disabled) 請選擇您的組別
              option(
                v-for="(option, optionIndex) in groupTypeOptions" 
                :key="optionIndex"
                :value="option"
              ) {{ option }}
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span(class="text-danger") *
          span 牧區
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="牧區" rules="required" v-slot="{ errors }")
            select(
              v-model="districtName"
              class="form-select")
              option(value="" disabled) 請選擇您的牧區
              option(
                v-for="(option, optionIndex) in districtOptions" 
                :key="optionIndex"
                :value="option"
              ) {{ option }}
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1")
          span(class="text-danger") *
          span 小組
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="小組" rules="required" v-slot="{ errors }")
            select(
              v-model="sendData.groupId"
              class="form-select")
              option(value="" disabled) 請選擇您的小組
              option(
                v-for="(option, optionIndex) in groupOptions" 
                :key="optionIndex"
                :value="option.groupId"
              ) {{ option.groupName }}
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span(class="text-danger") *
          span 姓名
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="姓名" rules="required" v-slot="{ errors }")
            input(
              v-model="sendData.name"
              type="text"
              placeholder="請輸入您的姓名"
              class="form-control"
            )
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span(class="text-danger") *
          span 電話
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="電話" rules="required|digits:10" v-slot="{ errors }")
            input(
              v-model="sendData.phone"
              type="text"
              placeholder="請輸入您的電話"
              class="form-control"
            )
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span Email
        div(class="col-lg-4 col-md-8 col-10")      
          input(
            v-model="sendData.email"
            type="text"
            placeholder="請輸入您的Email"
            class="form-control"
          )
      div(
        class="row mb-3 justify-content-center")
        legend(class="col-2 col-form-label pt-0 px-1") 
          span(class="text-danger") *
          span 性別
        div(class="col-lg-4 col-md-8 col-10")
          div(class="form-check form-check-inline float-start")
            input(
              v-model="sendData.gender"
              class="form-check-input"
              type="radio"
              value="男"
            )
            label(
              class="form-check-label"
            ) 男性
          div(class="form-check form-check-inline float-start")
            input(
              v-model="sendData.gender"
              class="form-check-input"
              type="radio"
              value="女"
            )
            label(
              class="form-check-label"
            ) 女性
          ValidationProvider(name="性別" rules="required" v-slot="{ errors }")
            input(
              v-model="sendData.gender"
              type="hidden"
              name="性別"
            )
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span(class="text-danger") *
          span 生日
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="生日" rules="required" v-slot="{ errors }")
            input(
              v-model="sendData.birthday"
              type="date"
              placeholder="請輸入您的生日"
              class="form-control"
              :max="maxDate"
            )
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(class="justify-content-center")
        button(
          class="btn btn-main rounded-pill mx-1"
          type="submit"
        ) 現場報名
</template>
<script>

export default {
  name: 'RegistrationSignInComponent',
  props: {
    formData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      groupTypeOptions: [],
      groups: [],
      groupType: '',
      districtName: '',
      maxDate: '',
      sendData: {
        name: this.formData ? this.formData.name : '',
        phone: this.formData ? this.formData.phone : '',
        email: this.formData ? this.formData.email : '',
        groupId: this.formData ? this.formData.groupId : '',
        gender: this.formData ? this.formData.gender : '',
        birthday: this.formData ? this.formData.birthday : '',
      }
    }
  },
  computed: {
    districtOptions () {
      const groupMap = this.groups
        .filter(group => group.groupTypeName === this.groupType)
        .reduce((acc, g) => {
          acc[g.districtName] = true
          return acc
        }, {})
      return Object.keys(groupMap).map(g => {return g})
    },
    groupOptions () {
      return this.groups
        .filter(g => g.districtName === this.districtName)
        .map(g => ({ groupName: g.groupName, groupId: g.groupId }))
    },
  },
  methods: {
    async getClcGroupSelect () {
      try {
        let groupRes = await this.$api.main.getClcGroupSelect()
        this.groupTypeOptions = groupRes.content.groupType
        this.groups = groupRes.content.group
      } catch (err) {
        console.log(err)
      }
    },
    async sendFormData () {
      try {
        let sendRes = await this.$api.main.sendSignInFormData(this.sendData)
        if (!sendRes.showalert && !sendRes.success) {
          this.$emit('error', sendRes)
        } else {
          this.$emit('success', sendRes)
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  created () {
    this.getClcGroupSelect()
    const today = new Date().toISOString().split('T')[0];
    this.maxDate = today
  }
}
</script>
