<template lang="pug">
div(class="app")
  div(class="header")
    img(
      class="clc-logo"
      src="../assets/logo.png"
    )
  div(
    v-if="!errorData"
    class="container pb-5")
    div(class="my-4")
      RegistrationSignIn(
        v-if="step!=='finish'"
        @success="handleSuccessToSendForm"
        @error="handleError"
      )
    div(v-if="step==='finish'" class="my-4")
      Finish(:finishData="finishData" @resign="handleResign")
  div(
    v-if="errorData"
    class="container")
    Error(
      :errorMessage="errorData.message"
    )
</template>
<script>
import RegistrationSignIn from '../components/SignIn/registrationSignIn'
import Error from '../components/SignIn/error'
import Finish from '../components/SignIn/finish'
export default {
  name: 'RegistrationSignInPage',
  components: {
    RegistrationSignIn,
    Error,
    Finish
  },
  data () {
    return {
      isLoading: false,
      step: 0,
      finishData: {},
      errorData: null,
      resignPage: false
    }
  },
  methods: {
    handleSuccessToSendForm (val) {
      this.step= 'finish'
      this.finishData = val
    },
    handleError (val) {
      this.errorData = val
    },
    handleResign () {
      this.$router.replace({ name: 'Sign', query: { step: 'reSignIn' }})
    }
  },
}
</script>
