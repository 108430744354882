<template lang="pug">
div(class="desc")
  div
    p 姓名: {{ finishData.content.name }}
    p 小組: {{ finishData.content.groupName }}
  div(class="py-3 message")
    p(class="mb-0 fs-5 text-center success") {{ finishData.content.message }}
  
  div(class="justify-content-center remark")
    p(class="text-center") 【備註】
    p(class="text-center notice mb-3") 已簽到成功者不要點擊「我要重填簽到」
    p(class="text-center") 簽到資訊有問題，請點擊按鈕：
    button(
      class="btn btn-gray rounded-pill mx-1"
      @click.prevent="handleResign"
    ) 我要重填簽到(有問題時才需要使用)
</template>
<script>

export default {
  name: 'Finish',
  props: {
    finishData: {
      type: Object
    }
  },
  methods: {
    handleResign () {
      document.cookie = "clc_form_info=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$emit('resign')
      location.reload()
    }
  }
}
</script>
<style scoped>
.remark {
  margin-top: 250px
}
</style>
