<template lang="pug">
div(class="app")
  div(class="header")
    img(
      class="clc-logo"
      src="../assets/logo.png"
    )
  div(
    v-if="!errorData"
    class="container pb-5")
    div(v-if="step === 0")
      Step0(
        :desc='desc'
        @error="handleError"
        @step1="handleStep1")
    div(
      v-if="step === 1"
      class="my-4"
    )
      Step1(
        :privacy='privacy'
        @agree="step = 2"
      )
    div(
      v-if="step === 2"
      class="my-4"
    )
      Step2(
        :formData="formData"
        :isHelper="isHelper"
        @success="handleSuccessToSendForm"
        @error="handleError"
      )
    div(
      v-if="step === 'finish'"
      class="my-4"
    )
      Finish(
        :finishData="finishData"
        @step1="handleStep1"
      )
  div(
    v-if="errorData"
    class="container")
    Error(
      :errorData="errorData"
    )
</template>
<script>
import Step0 from '../components/Registration/step0'
import Step1 from '../components/Registration/step1'
import Step2 from '../components/Registration/step2'
import Finish from '../components/Registration/finish'
import Error from '../components/Registration/error'

export default {
  name: 'Registration',
  components: {
    Step0,
    Step1,
    Step2,
    Finish,
    Error
  },
  data () {
    return {
      isLoading: false,
      step: 0,
      finishData: {},
      errorData: null,
      privacy: '',
      desc: '',
      formData: {},
      isHelper: false
    }
  },
  methods: {
    async getFormDataDesc () {
      try {
        let res = await this.$api.main.getFormDataDesc({
          token: '',
        })
        this.privacy = res.content.privacySignDesc
        this.desc = res.content.htmlDesc
      } catch (err) {
        console.log(err)
      }
    },
    handleStep1 (val) {
      this.formData = val.res
      this.isHelper = val.isHelper
      this.step = 1
    },
    handleSuccessToSendForm (val) {
      this.step= 'finish'
      this.finishData = val
    },
    handleError (val) {
      this.errorData = val
    },
    handleBack() {
      this.errorData = null
    }
  },
  created () {
    this.getFormDataDesc()
  }
}
</script>
