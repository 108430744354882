<template lang="pug">
div
  h2 請選擇簽到者姓名
  ValidationObserver(v-slot="{ handleSubmit }")
    form(@submit.prevent="handleSubmit(sendFormData)")
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span(class="text-danger") *
          span 姓名
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="姓名" rules="required" v-slot="{ errors }")
            select(
              v-model="registrationId"
              class="form-select")
              option(value="" disabled) 請選擇您的姓名
              option(
                v-for="(option, optionIndex) in loveSevenRegistration" 
                :key="optionIndex"
                :value="option.registrationId"
              ) {{ option.name }}
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(class="justify-content-center")
        button(
          class="btn btn-main rounded-pill mx-1"
          type="submit"
        ) 簽到
</template>
<script>

export default {
  name: 'ReSignIn',
  props: {
    loveSevenRegistration: {
      type: Array
    }
  },
  data () {
    return {    
      registrationId: this.loveSevenRegistration[0].registrationId,
    }
  },
  methods: {
    sendFormData () {
      this.$emit('submit', this.registrationId)
    }
  },
 
}
</script>
