<template lang="pug">
div(class="desc")
  div
    p 姓名: {{ finishData.name }}
    p 手機: {{ finishData.phone }}
    p 小組: {{ finishData.groupName }}
  div(class="py-3 message")
    p(class="mb-0 fs-5 text-center success") {{ finishData.titleDtl }} 報名成功
  div(class="mt-5 justify-content-center")
    button(
      class="btn btn-main rounded-pill mx-1"
      @click.prevent="handleOthers"
    ) 幫別人報名
</template>
<script>

export default {
  name: 'Finish',
  props: {
    finishData: {
      type: Object
    }
  },
  methods: {
    handleOthers () {
      this.$emit('step1', { isHelper: true })
    }
  }
}
</script>
