<template lang="pug">
div
  div(class="mt-3 justify-content-center")
    button(
      class="btn btn-main rounded-pill mx-1"
      @click.prevent="handleSelf"
    ) 本人報名
    button(
      class="btn btn-main rounded-pill mx-1"
      @click.prevent="handleOthers"
    ) 幫別人報名
  img(
    class="main-bg my-2 d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block"
    alt="title"
    src="@/assets/pc_banner.jpg")
  img(
    class="main-bg my-2 d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none"
    alt="title"
    src="@/assets/mobile_banner.jpg")
  div(v-html="desc")
  div(class="mt-2 justify-content-center")
    button(
      class="btn btn-main rounded-pill mx-1"
      @click.prevent="handleSelf"
    ) 本人報名
    button(
      class="btn btn-main rounded-pill mx-1"
      @click.prevent="handleOthers"
    ) 幫別人報名
</template>
<script>

export default {
  name: 'Step0',
  props: {
    desc: {
      type: String,
      default: ''
    }
  },
  methods: {
    async handleSelf () {
      const clc_form_info_cookie = document.cookie.split(';').find((item) => { return item.indexOf('clc_form_info') !== -1 });
      try {
        let res = await this.$api.main.getFormData({
          token: clc_form_info_cookie ? clc_form_info_cookie.split('clc_form_info=')[1] : '',
        })
        if (!res.showalert && !res.success) {
          this.$emit('error', res)
        } else {
          this.$emit('step1', { res: res.content, isHelper: false })
        }
      } catch(err) {
        console.log(err)
      }
    },
    handleOthers () {
      this.$emit('step1', { isHelper: true })
    }
  }
}
</script>
