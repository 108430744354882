<template lang="pug">
div
  h2 戀愛七學分課程簽到
  ValidationObserver(v-slot="{ handleSubmit }")
    form(@submit.prevent="handleSubmit(sendFormData)")
      div(
        class="row mb-3 justify-content-center")
        label(class="col-2 col-form-label px-1") 
          span(class="text-danger") *
          span 手機
        div(class="col-lg-4 col-md-8 col-10")
          ValidationProvider(name="手機" rules="required|digits:10" v-slot="{ errors }")
            input(
              v-model="phone"
              type="text"
              placeholder="請輸入您的手機"
              class="form-control"
            )
            span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
      div(class="justify-content-center")
        button(
          class="btn btn-main rounded-pill mx-1"
          type="submit"
        ) 簽到
</template>
<script>

export default {
  name: 'ReSignIn',
  data () {
    return {    
      phone: '',
    }
  },
  
  methods: {
    sendFormData () {
      this.$emit('submit', this.phone)
    }
  },
 
}
</script>
