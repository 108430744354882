import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'
import Registration from './pages/Registration.vue'
import SignIn from './pages/SignIn.vue'
import RegistrationSignIn from './pages/RegistrationSignIn'
import './style/style.css'
import api from './api'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import tw from 'vee-validate/dist/locale/zh_TW.json'
import * as rules from 'vee-validate/dist/rules'
import VueSweetalert2 from 'vue-sweetalert2'
import Dayjs from 'vue-dayjs';
import 'sweetalert2/dist/sweetalert2.min.css';

document.title = '戀愛必修的七堂課 ｜ 戀愛講座' 
Vue.config.productionTip = false
Vue.prototype.$api = api

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('tw', tw);
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(VueSweetalert2);
Vue.use(Dayjs);

const routes = [
  { path: "/",name: 'Registration', component: Registration },
  { path: '/sign', name: 'Sign', component: SignIn },
  { path: '/registrationSignIn', name: 'RegistrationSignInPage', component: RegistrationSignIn }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
