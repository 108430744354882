<template lang="pug">
div(class="desc")
  div(v-html="privacy")
  div(class="form-check agreeSign my-4 mx-auto")
    input(
      v-model="agreeSign"
      class="form-check-input"
      type="checkbox")
    label(class="form-check-label") 【←點選】我同意簽署
  div(class="justify-content-center")
    button(
      v-if="agreeSign"
      @click.prevent="agree"
      class="btn btn-main rounded-pill mx-1"
    ) 下一步
</template>
<script>

export default {
  name: 'Step1',
  props: {
    privacy: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      agreeSign: false,
    }
  },
  methods: {
    agree () {
      this.$emit('agree')
    }
  },
}
</script>
